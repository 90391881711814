import downloadData from '@/libs/modules/mixpanel/downloadData'
import downloadVideoData from '@/libs/modules/mixpanel/downloadVideoData'
import paymentData from '@/libs/modules/mixpanel/paymentData'
import actorData from '@/libs/modules/mixpanel/actorData'
import CONSTANTS from '@/config/constants'
const {PAYMENT_METHOD, PAYMENT_PLATFORM} = CONSTANTS

// TODO: 믹스패널 사용법 문서 작성
export class MixpanelDatahouse {
  static instance
  #store
  #i18n
  #eventList
  #auth

  constructor(store, i18n, auth) {
    this.#store = store
    this.#i18n = i18n
    this.#auth = auth
    this._setEventExecute()
    downloadData.setStore(store)
    downloadVideoData.setStore(store)
    paymentData.setStore(store)
    paymentData.setI18n(i18n)
    actorData.setStore(store)
  }

  _userStore() {
    return this.#store.getters['typecast/user/me']
  }

  _setEventExecute() {
    this.#eventList = {
      download: ({key, callback, queries, keyOverride}) => {
        return downloadData.parseEditorDocument({key, callback, queries, keyOverride})
      },
      videoDownload: ({key, sendEvent}) => {
        return downloadVideoData.parseVideo({key, sendEvent})
      },
      slideDownload: ({key, sendEvent}) => {
        return downloadVideoData.parseVideoSlide({key, sendEvent})
      },
      planItem({planId, period}) {
        return paymentData.getPlanInfo(planId, period)
      },
      actor({actor, actorId}) {
        if (actor) {
          return actorData.getActorInfo(actor)
        } else if (actorId) {
          return actorData.getActorInfoById(actorId)
        }
      },
    }
  }

  getIdentifier() {
    return this._userStore().uid
  }

  getSuperProperties() {
    const user = this._userStore()
    const plan = user.plan
    return {
      anonymous: this.#auth.isAnonymous(),
      plan_code: plan.current.package_id,
      plan_cycle: plan.current.period,
      plan_name: CONSTANTS.PLAN_NAME[plan.current.name],
      plan_price: user.package.total_amount,
      plan_platform: this.getPaymentPlatform(),
      plan_method: this.getPaymentMethod(),
      email_domain: user.email?.split('@')[1] || null,
      login_provider: user.signup_platform?.split('.')[0] || null,
    }
  }

  getUserData() {
    const user = this._userStore()
    const plan = user.plan

    return {
      anonymous: this.#auth.isAnonymous(),
      plan_code: plan.current.package_id,
      plan_cycle: plan.current.period,
      plan_name: CONSTANTS.PLAN_NAME[plan.current.name],
      plan_price: user.package.total_amount,
      plan_platform: this.getPaymentPlatform(),
    }
  }

  getPaymentMethod() {
    const {STRIPE, KAKAOPAY, KAKAOPAY_DIRECT, NICE, TOSS, CORPORATION_CARD} = PAYMENT_METHOD
    const user = this._userStore()
    if (user.payment_info.is_corporation_card) {
      return CORPORATION_CARD
    }
    const pg = user.payment_info.pg
    switch (pg) {
      case NICE:
      case TOSS:
      case STRIPE:
        return 'creditcard'
      case KAKAOPAY:
      case KAKAOPAY_DIRECT:
        return 'kakao'
      default:
        return ''
    }
  }

  getPaymentPlatform() {
    const user = this._userStore()
    const pg = user.payment_info.pg

    switch (pg) {
      case PAYMENT_METHOD.NICE:
      case PAYMENT_METHOD.KAKAOPAY:
        return PAYMENT_PLATFORM.IAMPORT
      case PAYMENT_METHOD.STRIPE:
        return PAYMENT_PLATFORM.STRIPE
      case PAYMENT_METHOD.TOSS:
        return PAYMENT_PLATFORM.TOSS
      case PAYMENT_METHOD.KAKAOPAY_DIRECT:
        return PAYMENT_PLATFORM.KAKAOPAY
      default:
        return ''
    }
  }

  getEventData(eventName, data = {}) {
    return this.#eventList[eventName](data)
  }

  /**
   * @param {import('vuex').Store} store
   * @param {VueI18n} i18n
   * @returns {MixpanelDatahouse}
   */
  static getInstance(store, i18n, auth) {
    if (!this.instance) {
      this.instance = new MixpanelDatahouse(store, i18n, auth)
    }
    return this.instance
  }
}

export default {
  install(Vue, {store, i18n}) {
    Vue.prototype.$mixpanelDatahouse = MixpanelDatahouse.getInstance(store, i18n, Vue.prototype.$auth)
  },
}
